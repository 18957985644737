import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownComp from "../../Ui/DropDown";
import moment from "moment";
import Beta from "../../Common/beta";
// import { ImageComponent } from "../../Common/FallbackImage";

export const statusStyleII = (status) => {
  switch (status) {
    case "In Progress":
      return "border-[#044E54] border-[1px] rounded-md px-[15px] py-1 bg-[#E5DAEE]";

    case "New":
      return "border-[#1D60D1] border-[1px] rounded-md px-[15px] py-1 bg-[#EEE8F4]";

    case "Open":
      return "border-[#E9E911] border-[1px] rounded-md px-[15px] py-1 bg-[#F5F1E3]";

    case "n":
      return "border-[#E9E911] border-[1px] rounded-md px-[15px] py-1 bg-[#F5F1E3]";

    case "PENDING":
      return "border-[#044E54] border-[1px] rounded-md px-[15px] py-1 bg-[#E5DAEE] capitalize";
      case "COMPLETED":
        return "border-[#044E54] border-[1px] rounded-md px-[15px] py-1 bg-[#D6DCE7]";
    default:
      break;
  }
};

export const statusStyle = (status) => {
  switch (status) {
    case "In Progress":
      return "border-[#044E54] border-[1px] rounded-full px-[15px] py-1 bg-[#E5DAEE]";

    case "New":
      return "border-[#1D60D1] border-[1px] rounded-full px-[15px] py-1 bg-[#EEE8F4]";

    case "Open":
      return "border-[#E9E911] border-[1px] rounded-full px-[15px] py-1 bg-[#F5F1E3]";

    case "n":
      return "border-[#E9E911] border-[1px] rounded-full px-[15px] py-1 bg-[#F5F1E3]";

    default:
      break;
  }
};
export const CustomerColumns = (tableDropDown, Data = []) => {
  return [
    {
      name: "ID",
      selector: (row) =>
        Data?.length > 0 && Data?.findIndex((item) => item?.id === row?.id) + 1,
      width: "70px",
      header: ({ name }) => <span title="Customer Id">{name}</span>,
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="flex items-center gap-2">
       
          {row?.businessName}
        </div>
      ),
      header: ({ name }) => <span title="Customer Name">{name}</span>,
    },

    {
      name: "Phone Number",
      selector: (row) => row?.businessPhoneNumber||'-',
      header: ({ name }) => <span title="Contact No">{name}</span>,
    },
    {
      name: <div>Rating  <Beta/></div>,
      selector: (row) => (
        <div className={`w-[100px]  text-center }`}>
          -
        </div>
      ),
      header: ({ name }) => <span title="Lead Status">{name}</span>,
    },
    {
      name: "Location",
      selector: (row) => row?.lga||'-',
      header: ({ name }) => <span title="Location">{name}</span>,
    },
    {
      name: "customer Tier",
      selector: (row) => row?.customerTier||'-',
      header: ({ name }) => <span title="customer Tier">{name}</span>,
    },

    {
      name: "Date Added",
      selector: (row) => moment(row?.createdAt).format("MMM Do YY"),
      header: ({ name }) => <span title="">{name}</span>,
    },
    {
      name: "Action",
      header: ({ name }) => <span title="">{name}</span>,
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => (
        <DropDownComp
          row={row}
          options={tableDropDown}
          dropDownText={<BsThreeDotsVertical color="#7572EA" />}
        />
      ),
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];
