import { BsThreeDotsVertical } from "react-icons/bs";
import { ImageComponent } from "../Common/FallbackImage";
import DropDownComp from "../Ui/DropDown";
import { formatMoney } from "../../utils/formatMoney";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Beta from "../Common/beta";

export const ProductColumns = (tableDropDown,Data) => {
  const { t, i18n, ready } = useTranslation();
  const price =t("lang.Price")
  const Availability =t("lang.Availability")
  return [
    {
      id:1,
      name: "ID",
      selector: (row) => Data?.findIndex((item)=>item?.id === row?.id)+1,
      width:'70px',
      sortable: true,

    },
    {
      id:2,
      name: "Photos",
      sortable: true,

      selector: (row) => (
        <div>
          <ImageComponent
            src={row?.productPhotoUrl?row?.productPhotoUrl:'/Images/noImage.png'}
            width={50}
            height={50}
            className="rounded-pill object-cover"
          />
        </div>
        
      ),
    },
    {
      id:3,
      name: "Name",
      selector: (row) => <div className="text-nowrap whitespace-nowrap">{row.productName}</div>,
      width:'200px',
      sortable: true,

    },

    {
      name: <div>Category               <Beta position={'right'}/>
      </div>,
      selector: (row) => '-',
      sortable: true,
      width:'200px',

    },
    {
      name: "Expiry Date",
      selector: (row) => <span>{row?.shelfLife} years</span>,
      sortable: true,
      width:'200px',
    },
    {
      name: price,
      selector: (row) => formatMoney(row?.costPerUnit,true,''),
      sortable: true,

    },
    {
      name: Availability,
      selector: (row) => <div className={row?.status==="Available"?'border-[1px] rounded-md bg-[#E6EEEE] text-[#444444] border-[#044E54] px-[15px] py-1':'border-[1px] rounded-md bg-[#FBEBEB] text-[#444444] border-[#D63434] px-[15px] py-1'}>{row?.status==="Available"?'In-Stock':'Out-Of-Stock'}</div>,
      sortable: true,
      width:'200px',

    },
    {
      name: "Date Added",
      selector: (row) => moment(row?.createdAt).format("MMM Do YY"),
      sortable: true,
      width:'200px',
    },
    {
      name: "Action",
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => ( <DropDownComp row={row} options={tableDropDown} dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>)
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];


export const DashBoardProductColumns =(Data=[])=>{
  return  [
    {
      name: "ID",
      selector: (row) => Data?.findIndex((item)=>item?.id === row?.id)+1,
      width:'70px'
    },
    {
      name: "Product Name",
      selector: (row) => row.productName,
    },
    {
      name: "Availability",
      selector: (row) => <div className={row?.status==="Available"?'text-[#00AEBC]':'text-[#D63434]'}>{row?.status}</div>,
    },
    {
      name: "Price (  )",
      selector: (row) => formatMoney(row?.costPerUnit),
    },
    {
      name: "Action",
      selector: (row) => <BsThreeDotsVertical color="#7572EA" />,
    },
  ];
}


export const getMonth = (setYearForGraph) =>{
  return  [{
    name:'Jan',value:1,action:()=>setYearForGraph({name:'Jan',value:1})
  },{
    name:'Feb',value:2,action:()=>setYearForGraph({name:'Feb',value:2})
  }, {
    name:'Mar',value:3,action:()=>setYearForGraph({name:'Mar',value:3})
  }, 
  ,{
    name:'April',value:4,action:()=>setYearForGraph({name:'April',value:4})
  },
  {
    name:'March',value:5,action:()=>setYearForGraph({name:'March',value:5})
  },
  {
    name:'June',value:6,action:()=>setYearForGraph({name:'June',value:6})
  },
  {
    name:'July',value:7,action:()=>setYearForGraph({name:'July',value:7})
  },
  {
    name:'Aug',value:8,action:()=>setYearForGraph({name:'Jan',value:8})
  },
  {
    name:'Sept',value:9,action:()=>setYearForGraph({name:'Aug',value:9})
  },
  {
    name:'Oct',value:10,action:()=>setYearForGraph({name:'Oct',value:10})
  },
  {
    name:'Nov',value:11,action:()=>setYearForGraph({name:'Nov',value:11})
  },
  {
    name:'Dec',value:12,action:()=>setYearForGraph({name:'Dec',value:12})
  },];

}

export const mSList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];



export const SwiperData =[
  {
    name:'Welcome!',
    desc:` Welcome to Pharmaserv Sales Enablement Software! Get more done and unlock your teams sales potential with our powerful tools and drive your business forward. `,
    icon:'/Images/Frame 1000002754.svg'
  },
  {
    name:'Discover Product management',
    desc:`Discover the power of product management. Add your products and SKUs efficiently for streamlined operations and increased sales.`,
    icon:'/Images/Frame 1000002754 (2).svg'
  },
  {
    name:'Create Your Field Rep',
    desc:`Boost your sales team's performance. Go to Field Rep feature and add your sales reps to empower them to excel in the field.`,
    icon:'/Images/Frame 1000002754 (2).svg'
  },
  
]