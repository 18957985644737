import React from 'react'
import ButtonComp from '../Ui/Button'
import { ImageComponent } from './FallbackImage'
import { BsPlusLg } from 'react-icons/bs'
import { useTranslation } from 'react-i18next';
import CsvDownload from './CsvReport';

export default function TopHeader({navigate,title,report,btnText,addBtn,addBtnAction,plus,csvData=[],filename,Csv}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-wrap lg:flex-nowrap justify-content-between mb-4 items-center">
        <h4 className="font-semibold ms-2 mb-2 mb-lg-0 block lg:inline-block w-full lg:w-fit">{title||t(`lang.P`)}</h4>
        <div className="flex  justify-content-end  gap-4 w-full lg:w-fit">
        {Csv &&  <CsvDownload
          filename={filename}
          Data={csvData}
          />}
        {!report &&  <ButtonComp
            btnClassName={
              "border-[#4F008A] w-[100%] border-[1px] rounded-pill flex-1 px-3 px-lg-4 py-2 py-lg-2"
            }
            text={
              <span className="flex gap-[5px] items-center  text-[14px]">
                <ImageComponent
                  src="/icon/downloadI.svg"
                  width={18}
                  height={18}
                />
                {t(`lang.R`)}
              </span>
            }
          />}
        {!addBtn &&  <ButtonComp
          onClick ={addBtnAction}
            btnClassName={
              "border-[#3DA84A] border-[1px] bg-[#3DA84A] rounded-pill sm:px-3 lg:px-[28px] py-2 py-lg-2"
            }
            text={
              <div className="flex gap-[5px] items-center text-white  text-[14px] px-[10px]">
                {!plus && <BsPlusLg size={20} />} {btnText||'Add Product'}
              </div>
            }
          />}
          
        </div>
      </div>
  )
}
