import React,{useState,useEffect} from 'react'
import ListViewInCanvas from '../../../Common/ListViewInCanvas'
// import { ImageComponent } from '../../../Common/FallbackImage'
import moment from 'moment'
import { statusStyleII } from '../Data'
import { IoIosArrowUp } from 'react-icons/io'
import { ImageComponent } from '../../../Common/FallbackImage'
import { BsThreeDotsVertical } from 'react-icons/bs'
import SelectComp from '../../../Ui/SelectComp'
import ButtonComp from '../../../Ui/Button'
import Beta from '../../../Common/beta'


export default function ViewCustomerDetailsComp({data,edit,handleSubmit}) {
   
    const [userData, setUserData] = useState(data);
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
        handleSubmit(userData)
        // console.log(e.target)
      };

      
      useEffect(() => {
        handleSubmit(userData)
      }, [handleSubmit, userData])
      
  return (
   <>
   { <div>
        <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px]  xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]'>
       <div className='row gy-4'>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Customer Name'}
            ItemName={userData?.contactPersonName}
            edit={edit}
            name='contactPersonName'
            onChange={handleOnChange}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Business Name'}
            ItemName={userData?.businessName}
            edit={edit}
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Phone Number'}
            ItemName={userData?.businessPhoneNumber}
            edit={edit}
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Total Revenue (NG)'}
            ItemName={'-'}
            disabled={true}
            edit={edit}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Date Added'}
            ItemName={moment(userData?.createdAt).format("MMM Do YY")}
            edit={edit}
            name='phoneNumber'
            onChange={handleOnChange}
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Email'}
            ItemName={userData?.email}
            edit={edit}
            name='email'
            onChange={handleOnChange}
            />
        </div>
     
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'State'}
            ItemName={userData?.state}
            edit={edit}
            type='date'
            disabled={true}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Location'}
            ItemName={userData?.lga}

            // customItem={<div className={statusStyleII(data?.status)}>{userData?.lga}</div>}
            disabled={true}
            edit={edit}
            // customItemI={
            //   <SelectComp
            //   value={userData?.status}
            //     options={[
            //       { name: "In Progress", id: "In Progress" },
            //       // {name:'Discontinued',id:'discontinued '},
            //       { name: "New", id: "New" },
            //       { name: "Open", id: "Open" },
            //     ]}
            //     name="status"
            //     onChange={handleOnChange}
            //   />
            // }
            />
        </div>
         
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Status'}
            ItemName={'-'}
            // edit={edit}
            // name='email'
            onChange={handleOnChange}
            />
        </div>
        <div className='col-lg-6 '>
            <ListViewInCanvas
            title={'Rating'}
            ItemName={'-'}
            // edit={edit}
            // name='email'
            onChange={handleOnChange}
            />
        </div>
       
        
       </div>
    </div>

    <Beta position={'right'}/>

    <div className='flex justify-center gap-[20px]'>
        <ButtonComp
        // onClick={()=>handleSubmit()}
        text={'Delete Customer'}
        btnClassName={'border-[#D63434] border-[1px] text-[#D63434] rounded-full px-[25px] py-2 text-[14px] font-medium'}
        />
        <ButtonComp
        // onClick={handleUpdateOrder}
        text={'Update Customer'}
        btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium'}
        />
    </div>
    {/* <div className='flex justify-center gap-[20px]'>
        <ButtonComp
        text={'Delete Customer'}
        btnClassName={'border-[#D63434] border-[1px] text-[#282728] rounded-full px-[25px] py-2 text-[14px] font-medium'}
        />
        <ButtonComp
        text={'Edit Customer'}
        btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium'}
        />
    </div> */}
    </div>}
    <div >
   
    </div>
   </>
  )
}
