/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import TopHeader from "../../Common/TopHeader";
import SubHeader from "../../Common/SubHeader";
import { CustomerColumns } from "./Data";
import ViewCustomerDetailsComp from "./submodules/ViewProductDetails";
import {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useUpdateLeadsMutation,
} from "../../../store/Customer/CustomerApi";
import { FiEdit } from "react-icons/fi";
import ButtonComp from "../../Ui/Button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
export default function ViewLeadDetails() {
  const navigate = useNavigate();
  const {id} =useParams()
  const [showCavans, setShowCavans] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [LeadData,setLeadData]=useState();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  const {
    data: CustomerData,
    // isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCustomerQuery();
  const {
    data: LeadsData,
    isLoading: LeadsLoader,
    // isSuccess: LeadsSuccess,
    refetch:LeadsRefetch
  } = useGetAllLeadsQuery();

  const [updateLeads,{isLoading,isError,data,error,isSuccess:updateLeadIsSuccess}] =useUpdateLeadsMutation()

  // console.log(data, "LeadsData");
  useEffect(() => {
    if(id){
      setShowCavans(true)
    }
  }, [id])

  useEffect(() => {
   if(updateLeadIsSuccess){
    LeadsRefetch();
    toast.success('Lead Updated Successfully')
    navigate(`/lead/`);
    setShowCavans(false)
   }
  }, [ updateLeadIsSuccess,LeadsRefetch])

  useEffect(() => {
    if(isError){
      Array.isArray(error?.data?.message)?error?.data?.message?.map((item)=>
        toast.error(item)
      ):  toast.error(error?.data?.message);
    }
   
    
  }, [error?.data?.message, isError])
  
  

  const handleUpdateLeads =async (data) => {
    // updateLeads
   await updateLeads(LeadData);
    // console.log(dataResult,'handleUpdateLeads123')
    // console.log(LeadData,'handleUpdateLeads')
  }

    // console.log(error,isError,'handleUpdateLeads123')
  
  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <FiEdit size={18} color="#4F008A" /> Edit
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/lead/${row?.id}`);
        setShowCavans(true);
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/refresh.svg" />
          Convert To Customer
        </div>
      ),
      action: (row) => {
        // navigate("/customer/1");
      },
    },
    // {
    //   name: (
    //     <div className="text-[#D63434] text-[13px] flex gap-2 items-center">
    //       <img alt="" src="/icon/red-trash.svg" />
    //       Delete Lead
    //     </div>
    //   ),
    //   action: (row) => {
    //     console.log(row, "row");
    //     setShowCavans(true);
    //   },
    // },
  ];
  return (
    <div>
      <TopHeader
        title={`${t(`lang.LEAD`)} (${LeadsData?.data?.length || 0})`}
        addBtn={true}
        // btnText={"Add Leads"}
      />
      <SubHeader
      showFilterBtn
      statusAllBtn
        sortBtn
        inputPlaceholder={"Search Lead Source, Company Name,Status"}
        onChange={(e)=>setSearchField(e.target.value)}      />
      <div>
        <TableComp
        loader={LeadsLoader}
          Data={LeadsData?.data
            ?.filter(
              (item) =>
                item?.name.toLowerCase().match(searchField?.toLowerCase()) ||
                item?.companyName
                  .toLowerCase()
                  .match(searchField?.toLowerCase())||
                   item?.status
                  .toLowerCase()
                  .match(searchField?.toLowerCase())
            )
            .reverse()}
          columnsComp={CustomerColumns(
            tableDropDown,
            LeadsData?.data?.length > 0 && [...LeadsData?.data]?.reverse()
          )}
          pagination
        />
      </div>
      <OffCanvasComp
        title={"Lead Preview"}
        ButtonComp={<ButtonComp
        loader={isLoading}
        onClick={()=>edit?handleUpdateLeads():setEdit(true)}
        // btnClassName={'bg-primaryI text-white py-2 px-5 rounded-full'}
        // text={edit?'Update Leads':'Edit Leads'}
        />}
        show={showCavans}
        bodyComponent={<ViewCustomerDetailsComp setShowCavans={setShowCavans} LeadsRefetch={LeadsRefetch} handleSubmit={setLeadData} edit={edit} data={LeadsData?.data?.find((item)=>Number(item?.id)===Number(id))} />}
        handleClose={() => {
          setShowCavans(false)
          navigate("/lead");
          setEdit(false)
        }}
      />
    </div>
  );
}
