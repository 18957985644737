import React, { useMemo, useState } from "react";
import { GoogleMap, LoadScript, Marker, useLoadScript } from "@react-google-maps/api";
import ListLocations from "./submodules/ListLocations";
import ButtonComp from "../../Ui/Button";
import { useGetLocationsQuery } from "../../../store/Customer/CustomerApi";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function ShowMapCompI({
  locationsArray = [],
  noShow = true,
  height,
  Data=[],
  isLoading=false
}) {
  const {customerId} =useParams()
  // const { data: DataMap, isLoading } = useGetLocationsQuery();
  const [mapLocation,setMapLocation] =useState()
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyADb8PSKrM-CcKxd2iab2QIH4LTIjmI3aM",
  });

  //  useEffect(() => {
    
  // //   // const hasReset = localStorage.getItem("hasReset");
  //   const getuserId = window!==undefined && localStorage.getItem("userId");
  //   if (getuserId !== customerId) {
  //     localStorage.setItem("hasReset", true);
  //  localStorage.setItem("userId", customerId);

  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 5000);
  //   }
  // }, [customerId]);
  // console.log(DataMap?.data,'DataMap?.data')
  // if (loadError) return <div>There was an error loading the map</div>;

   // load the Google Maps API if it's not already loaded
  //  if (!window.google || !window.google.maps) {
  //   loadError || window.location.reload(true);
  //   return null;
  // }

  if (isLoading && !isLoaded ) return <div>Loading...</div>;

  return <Map Data={mapLocation||Data}    loadError={loadError}
  isLoaded={isLoaded} />;
}




function Map({ Data, loadError, isLoaded }) {
  if (loadError || !isLoaded || !window.google || !window.google.maps) {
    return null;
  }
  const markers = Data?.map((item, i) => (
    <Marker
      key={i}
      position={{ lat: Number(item?.latitude), lng: Number(item?.longitude) }}
    />
  ));
  return (
    <GoogleMap
      zoom={12}
      center={{ lat: 6.505336886990683, lng: 3.3804718091104298 }}
      mapContainerStyle={{ height: "90vh", width: "100%" }}
    >
      {Data?.length >=1 && markers}
    </GoogleMap>
  );
}
