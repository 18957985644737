import React, { useEffect } from "react";
import { ImageComponent } from "../Common/FallbackImage";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import ButtonComp from "../Ui/Button";
import { TableComp } from "../Common/TableComp";
import ContainerComp from "../Common/Container";
import { SimpleChart } from "../Common/Charts/Chart1";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  useDashboard_metricQuery,
  useGraphDataQuery,
  useMainDashBoardMapQuery,
  useTopAreasDataQuery,
} from "../../store/DashBoard/DashBoardApi";
import { formatMoney } from "../../utils/formatMoney";
import { useGetAllProductQuery } from "../../store/Product/ProductApi";
import { DashBoardProductColumns, SwiperData, getMonth, mSList } from "./Data";
import {  YearMonth, firstDateOfTheMonth, storage } from "../../utils/helper";
import { useGetAllOrderQuery } from "../../store/Order/OrderApi";
import DateFilter from "../Common/DateFilter";
import { useNavigate } from "react-router-dom";
import ShowMapCompI from "./Schedule/showMap copy";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Beta from "../Common/beta";
import DropDownComp from "../Ui/DropDown";
import NoData from "../Common/NoData";
import ModalComp from "../Ui/ModalComp";
import { useState } from "react";
import SwiperComp from "../Common/Swiper";
export default function DashBoardComp() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [isNewUser,setIsNewUser]=useState(false)
  const [startDate, setStartDate] = useState(firstDateOfTheMonth());
  const [endDate, setEndDate] = useState(new Date());
  const d = new Date();
  const swiperRef = React.useRef(null);

// let name = month[d.getMonth()];
  const [yearForGraph,setYearForGraph]=useState({name:mSList[d.getMonth()],value:d.getMonth()});
  const [months,setMonths]=useState(YearMonth(t))

  console.log(yearForGraph,'yearForGraph')

  // const { t } = useTranslation();
  const { t, i18n, ready } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  // useEffect(() => {
  //   setMonths(YearMonth())
  // }, [])
  

  const StartDateEnhanced = moment(startDate).format("YYYY-MM-DD");
  const EndDateEnhanced = moment(endDate).add(1, 'days').format("YYYY-MM-DD");

  // console.log(moment(startDate).format("YYYY-MM-DD"),endDate,'firstDateOfTheMonth')

  const {
    data: dashBoardMetricData,
    isLoading: dashBoardMetricLoader,
    isFetching: dashBoardMetricFetching,
  } = useDashboard_metricQuery(
    {
      StartDateEnhanced, EndDateEnhanced
    },
    {
       skip:!startDate||!endDate,
       refetchOnMountOrArgChange: false,

      // skipToken: startDate||startDate
    },
  );
  const {
    data: ProductData,
    isLoading: ProductLoader,
    isSuccess: ProductSuccess,
  } = useGetAllProductQuery();
  const {
    data: GraphData,
    isLoading: GraphDataLoader,
    isSuccess: GraphDataSuccess,
  } = useGraphDataQuery(
    {
      StartDateEnhanced, EndDateEnhanced
    },
     {skip:!yearForGraph?.value}
  );
  // const {
  //   data: GraphData,
  //   isLoading: GraphDataLoader,
  //   isSuccess: GraphDataSuccess,
  // } = useGraphDataQuery(
  //   {monthId:yearForGraph?.value},
  //    {skip:!yearForGraph?.value}
  // );
  const {
    data: OrderData,
    isLoading: OrderDataLoader,
    isSuccess: OrderDataSuccess,
  } = useGetAllOrderQuery();
  const {
    data: TopAreaData,
    isLoading: TopAreaDataLoader,
    isSuccess: TopAreaDataSuccess,
  } = useTopAreasDataQuery();
  const {
    data: mainDashBoardMap,
    isLoading: mainDashBoardMapLoader,
    // isSuccess: mainDashBoardMapSuccess,
  } = useMainDashBoardMapQuery();

  // console.log(mainDashBoardMap?.data,'mainDashBoardMap')
  // console.log(YearMonth(t)[1],'YearMonth')
  const getGraphData = () => {
    const newArray = [];
    // yearForGraph===2023 ? 
    //  GraphData?.data?.map((item) => item?.month <= new Date().getMonth()&&
    //   newArray.push({
    //     month: months[item?.month - 1],
    //     totalSalesOrders: item?.orders?.totalAmount,
    //     totalSalesCollection: item?.orders?.totalSalesCollection,
    //   })
    //   // :
    // ):
    GraphData?.data?.map((item) => 
      newArray.push({
        month: mSList[item?.month - 1],
        totalSalesOrders: item?.orders?.totalAmount,
        totalSalesCollection: item?.orders?.totalSalesCollection,
      })
    )
    // console.log(newArray,'newArray')
    return newArray;
  };

  useEffect(() => {
    if(storage.localStorage.get('IsNewUser')){
      setIsNewUser(true)
    }
  }, [])
  

  // console.log(dashBoardMetricData,'dashBoardMetricData')

  if (!ready) {
    return <div></div>;
  }


  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleCloseSplashScreen=()=>{
    setIsNewUser(false); 
      storage.localStorage.set('IsNewUser',false)
  };

  const OrderList =OrderDataSuccess? OrderData?.data?.order:[];


  function compareViaDate(a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  }
  return (
    <div>
      <ModalComp
      show={isNewUser}
      backdrop="static"
      handleClose={()=>{setIsNewUser(false); 
        storage.localStorage.set('IsNewUser',false);}}
      close={false}
      bodyComponents={      
        <div className="px-3">
        <SwiperComp
        swiperRef={swiperRef}
        Data={SwiperData}
        onSlideChange={handleSlideChange}
      
      />
      <div className="flex justify-center gap-3 px-[40px] mt-10">
        <ButtonComp
        text={'Skip'}
        btnClassName={'w-[100%] border text-[18px] font-semibold  text-[#6F99B9] h-[41px] rounded-md'}
        wrapper={'w-full'}
        onClick={handleCloseSplashScreen}
        />
        <ButtonComp
        text={currentIndex===2?'Continue':'Next'}
        btnClassName={'w-[100%] border text-[18px] font-semibold bg-[#5F65F6] text-white h-[41px] rounded-md'}
        wrapper={'w-full'}
        onClick={currentIndex===2?handleCloseSplashScreen:goNext}
        />
      </div>
        </div>
    }
      />
      <div className="d-flex justify-content-between mb-4">
        <h4 className="font-2 ms-2">{t("lang.Overview")} </h4>
        {/* <h4 className="font-2 ms-2">{t('lang.hello')} </h4>
        <span>
          Browser Language:{lng}
        </span> */}
        <DateFilter t={t} 
         filterLoader={dashBoardMetricLoader||dashBoardMetricFetching}
         filterStartDate={startDate}
         filterEndDate={endDate}
         filterStartSetDate={setStartDate}
         filterEndSetDate={setEndDate}
         dateFormat="MM/dd/yyyy"
        />
      </div>

      <div className="flex mb-5  flex-wrap">
        <div className="w-full lg:w-[28%] flex-grow h-full">
          <div className="px-lg-2">
            <div className="bg-primaryII px-4 py-3 rounded mb-3 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ImageComponent src="/icon/wallet.png" width={60} height={60} />
                <div>
                  <BsThreeDotsVertical size={20} />
                </div>
              </div>
              {/*  */}
              <div className="fw-medium blackII mb-4">{t("lang.TSO")}</div>
              <h4 className="blackI font-5 fw-semibold">
                {formatMoney(dashBoardMetricData?.data?.total_orders_amount||'0')}
              </h4>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="w-full lg:w-[36%] flex-grow  mb-3 mb-lg-0">
          <div className="px-lg-2">
            <div className="card">
              <div className="card-body">
                <div className="mt-3 d-flex justify-content-between align-items-center">
                  {/* <div className="grayIII">Total Product</div> */}
                  <div className="grayIII">{t(`lang.TSC`)}</div>
                  <h4>
                    {formatMoney(
                    dashBoardMetricData?.data?.paid_orders?.total_amount||'0',false
                    )}
                  </h4>
                </div>
                <hr />
                <div className="grayIII fw-medium">{t(`lang.TD`)}</div>
                <div className="mt-3 d-flex justify-content-between align-items-center">
                  <h4>
                    {formatMoney(
                      dashBoardMetricData?.data?.pay_later_orders?.total_amount||'0',false
                    )}
                  </h4>
                  <div className="grayIII border rounded-pill px-2 py-1 d-flex gap-1 fw-bold align-items-center">
                    {/* <ImageComponent src="/icon/up.png" width={15} height={15} />
                    +20% */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="w-full lg:w-[36%] flex-grow  mb-3 mb-lg-0">
          <div className="px-lg-2">
            {" "}
            <div className="card">
              <div className="card-body">
              {/* <Beta position={'right'}/> */}
                <div className="mt-3 d-flex justify-content-between align-items-center">
                 
                  <div className="grayIII">{t(`lang.TOC`)}</div>
                  <h4>{dashBoardMetricData?.data?.total_orders_count}</h4>
                </div>
                <hr />
                <div className="grayIII fw-medium">{t(`lang.TPO`)}</div>
                <div className="mt-3 d-flex justify-content-between align-items-center">
                  <h4>{dashBoardMetricData?.data?.paid_orders?.count}</h4>
                  <div className="grayIII border rounded-pill px-2 py-1 d-flex gap-1 fw-bold align-items-center">
                    {/* <ImageComponent
                      src="/icon/down.png"
                      width={15}
                      height={15}
                    />
                    -20% */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex  flex-wrap mb-5">
        <div className="w-full  lg:w-[60%] flex-grow h-full flex  items-stretch">
          <div className="lg:px-2 w-100  mb-3 mb-lg-0">
            <div className=" border h-100 ">
              <div className="bg-gray mb-2 p-3 d-flex justify-content-between align-items-center">
                <h5 className="blackIV font-2 mb-0">{t("lang.TP")}</h5>
                <ButtonComp
                  onClick={() => navigate("/product")}
                  text={t(`lang.VA`)}
                  btnClassName="success h14 border-0"
                />
              </div>
              <div className="px-3">
                {/* {console.log(ProductData?.data?.length<=0,'ProductData')} */}
                {!ProductLoader&&ProductData?.data?.length<=0?
              <NoData/> :
<TableComp
                  Data={ProductData?.data?.slice(0, 4)}
                  columnsComp={DashBoardProductColumns(ProductData?.data)}
                />
              }
              {/* <NoData/> */}
                
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="w-full lg:w-[36%] flex-grow items-stretch flex">
          <div className="lg:px-2 w-100 h-full">
            <div className=" rounded h-full">
              <ContainerComp containerClassName={"h-100"}>
              {/* <Beta position={'right'}/> */}
                <div className="flex justify-between items-center mb-4 ">
                  
                <div className="font-2 h18 ">{t(`lang.UCB`)}</div>
               
                </div>
                <div className="d-flex gap-3 align-items-center mb-3">
                  <div className="h14 d-flex gap-2 align-items-center">
                    <GoPrimitiveDot color="#4F008A" size={18} /> {t(`lang.SO`)}
                  </div>
                  <div className="h14 d-flex gap-2 align-items-center">
                    <GoPrimitiveDot color="#7572EA" size={18} />
                    {t(`lang.TSC`)}
                  </div>
                </div>
                <div>
                  <SimpleChart Data={getGraphData().reverse()} />
                </div>
              </ContainerComp>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex  flex-wrap mb-5">
        <div className="w-full  lg:w-[60%] flex-grow h-full flex  items-stretch">
          <div className="lg:px-2 w-100  mb-3 mb-lg-0">
            <div className=" border h-100 rounded-md">
              {/*  */}
              <Beta position={'right'}/>

              <div className=" mb-2 py-[20px] px-4  d-flex justify-content-between align-items-center border-[#F4EFF7]  border-b-[1px]">
                <h5 className="blackIV font-2 mb-0">Track Your Orders</h5>
                <ButtonComp
                  text={"Open"}
                  btnClassName="primaryI font-medium h14 border-0"
                />
              </div>
              <div className="px-3 h-[700px]">
                <ShowMapCompI noShow={false} height="700px"
                Data={mainDashBoardMap?.data}
                isLoading={mainDashBoardMapLoader}
                />
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="w-full lg:w-[36%] flex-grow items-stretch">
          <div className="lg:px-2 w-100 items-stretch">
            <div className="h-full">
              <div className="rounded h-full  mb-4">
                <ContainerComp
                  bodyClassName="p-0 pb-4"
                  containerClassName={"h-100"}
                >
                  <div className=" mb-2 py-[20px] px-4 d-flex justify-content-between align-items-center border-[#F4EFF7]  border-b-[1px]">
                    <h5 className="blackIV font-2 mb-0">{t(`lang.RO`)}</h5>
                    <ButtonComp
                    onClick={()=>{
                      navigate('/order_management');
                      scroll(0,0);
                    }}
                      text={t(`lang.VA`)}
                      btnClassName="success font-medium h14 border-0 font-6"
                    />
                  </div>
                  {/* {console.log(    [...OrderList]?.sort(compareViaDate),'hello')} */}
                  {!OrderDataLoader&&OrderData?.data?.order?.length<=0 ? <NoData/> :
               [...OrderList]?.sort(compareViaDate).slice(undefined,4)

                    ?.map((item, i) => (
                      <div
                        className="row justify-between items-center px-2 px-lg-4"
                        key={i}
                      >
                        <div className="col-10 col-lg-9 py-1">
                          <div className="flex gap-[8px] items-center ">
                            <div className="col-3 col-lg-2 text-center flex  justify-center ">
                              <div className="w-[42px] h-[42px] bg-[#7572EA] rounded-full flex justify-center items-center">
                                <div className="text-white capitalize">{item?.customer?.businessName?.substring(0,1)
                               
                                }{ item?.customer?.businessName.split[' ']}</div>
                              
                              </div>
                            </div>
                            <div className="col-8 py-[10px]">
                              <p className="blackI mb-0 font-semibold">
                              {item?.customer?.businessName}
                              </p>
                              {/* <div className="h14">
                                {item?.customer?.businessName}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-lg-3 ">
                          <div className=" text-end flex justify-end pe-3">
                            {" "}
                           <DropDownComp
                           dropDownText={ <BiDotsVerticalRounded size={20} color="#7572EA" />
                          
                          }
                          options={[
                            {name:'Show Details',action:()=>{navigate(`/order_management/${item?.id}`)}}
                          ]}
                          noDivider={false}
                           />
                          </div>
                        </div>
                      </div>
                    ))}
                </ContainerComp>
              </div>
              {/*  */}
              <div className="rounded h-full ">
                <ContainerComp
                  bodyClassName="p-0 pb-3"
                  containerClassName={"h-100"}
                >
                  <div className=" mb-2 py-[20px] px-4 d-flex justify-content-between align-items-center border-[#F4EFF7]  border-b-[1px]">
                    <h5 className="blackIV font-2 mb-0">{t("lang.TA")}</h5>
                    <ButtonComp
                      onClick={() => {
                        // scroll(0,0)
                        navigate("/top_area");
                      }}
                      text={t(`lang.VA`)}
                      btnClassName="success font-medium h14 border-0 font-6"
                    />
                  </div>

                  {!TopAreaDataLoader&&TopAreaData?.data?.areas?.length<=0 ? <NoData/> :
                  TopAreaData?.data?.areas
                    ?.slice(undefined, 4)
                    ?.map((item, i) => (
                      <div
                        className="row justify-between items-center px-4 py-3"
                        key={i}
                      >
                        <div className="col-3 ">
                          <div className="text-[#282728] font-semibold">
                            {item?.lga}
                          </div>
                        </div>
                        <div className="col-9">
                          <div className=" text-end flex justify-end pe-lg-3">
                            <div className="font-semibold text-[#757575] flex items-center gap-2 gap-lg-4">
                              {" "}
                              {formatMoney(item?.totalAmount, false)}
                              <div className="grayIII border rounded-pill px-3 py-1 d-flex gap-1 fw-bold justify-center align-items-center">
                                <ImageComponent
                                  src="/icon/up.png"
                                  width={15}
                                  height={15}
                                />
                                +20%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </ContainerComp>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{/* <MapWithMarker locations={location}/> */}</div>
    </div>
  );
}
