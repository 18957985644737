import React from "react";
import { SideBarData } from "../Data/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { ImageComponent } from "./FallbackImage";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth";
import { useGetUserProfileQuery } from "../../store/User/userApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { baseQuery } from "../../store/api";
import { DashBoardApi } from "../../store/DashBoard/DashBoardApi";
import { ProductApi } from "../../store/Product/ProductApi";
import { CustomerApi } from "../../store/Customer/CustomerApi";
import { authApi } from "../../store/auth/authApi";
import { OrderApi } from "../../store/Order/OrderApi";

export default function SideBar({ active }) {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  const { data: userProfile } = useGetUserProfileQuery();
  const [dropDown, setDropDown] = useState(false);
  // console.log(userProfile?.data?.company?.logo, "userProfile");

  const logo =
    userProfile?.data?.company?.logo ===
    "https://suppliers-product-img.s3.amazonaws.com/Chaise%20Lounge.jpg"
      ? "/icon/hetero.jpg"
      : userProfile?.data?.company?.logo;

  const dispatch = useDispatch();
  return (
    <div className="overflow-y-scroll h-[100vh] check overflow-x-hidden">
      <div
        className="position-relative mb-5 text-center flex justify-center"
        style={{ height: "180px" }}
      >
        <ImageComponent
          width={200}
          height={200}
          className="object-contain"
          src={userProfile?.data?.company?.logo ? logo : "/Images/noUser.jpg"}
        />
      </div>
      <div className="px-3 ">
        <div className="">
          {SideBarData(t)?.map(
            ({ name, icon, icon1, path, activeI, subItem }, i) => (
              <div
                key={i}
                // to={path}
              >
                <div
                  className={`ps-4 d-flex align-items-center gap-3 fw-medium mb-2 py-3 rounded-pill px-3 cursor-pointer ${
                    active === activeI
                      ? "bg-primaryI text-white animate__fadeIn animate__animated"
                      : "blackII"
                  }`}
                  onClick={() => navigate(path)}
                >
                  {active === activeI ? icon : icon1}
                  {name}
                </div>
              </div>
            )
          )}
        </div>
        <div className=" d-flex align-items-end" style={{ height: "20vh" }}>
          <div
            onClick={() => {
              dispatch(DashBoardApi.util.resetApiState());
              dispatch(ProductApi.util.resetApiState());
              dispatch(CustomerApi.util.resetApiState());
              dispatch(authApi.util.resetApiState());
              dispatch(OrderApi.util.resetApiState());
              dispatch(logout())
              // setTimeout(() => {
              //   dispatch(logout())
              // }, 3000);

            }}
            className={`ps-5 d-flex align-items-center gap-4 fw-medium mb-2 py-3 rounded-pill pointer ${"  animate__fadeIn animate__animated blackII"}`}
          >
            <ImageComponent
              width={26}
              height={26}
              src="/Dashboard/logout.png"
            />
            {t(`lang.LG`)}
          </div>
        </div>
      </div>
    </div>
  );
}
