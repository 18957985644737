import { useTranslation } from 'react-i18next';
import { ImageComponent } from '../Common/FallbackImage'
import { useEffect } from 'react';

export const SideBarData =()=>{
    const { t,i18n } = useTranslation();

    useEffect(() => {
      const lng = navigator.language;
      i18n.changeLanguage(lng);
    }, [i18n]);
  
    return [
        {
            name:t(`lang.DASH`),
            icon:<ImageComponent width={26} src='/Dashboard/1a.svg' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/dashboard1.png' />,
            path:'/',
            activeI:1
        },
        {
            name:t(`lang.PRO`),
            icon:<ImageComponent width={26} src='/Dashboard/product.png' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/product1.png' />,
            path:'/product',
            activeI:2
        },
        {
            name:t(`lang.MQ`),
            icon:<ImageComponent width={26} src='/Dashboard/stickynoteb.svg' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/stickynote1.png' />,
            path:'/order_management',
            activeI:3,
        },
        {
            name:t(`lang.LEAD`),
            icon:<ImageComponent width={26} src='/Dashboard/profile-2user.svg' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/profile-2user1.png' />,
            path:'/lead',
            activeI:7,
            // name:'Leads',path:'/lead'
            
    
        },
        {
            name:t(`lang.CUSTOMER`),
            icon:<ImageComponent width={26} src='/Dashboard/profile-2user.svg' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/profile-2user1.png' />,
            path:'/customer',
            activeI:4,
            
    
        },
        {
            name:t(`lang.SCH`),
            icon:<ImageComponent width={26} src='/Dashboard/task-square.svg' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/task-square1.png' />,
            path:'/schedule',
            activeI:5
        },
        {
            name:t(`lang.AC`),
            icon:<ImageComponent width={26} src='/Dashboard/security-safe.svg' />,
            icon1:<ImageComponent width={26} height={26} src='/Dashboard/security-safe1.png' />,
            path:'/console',
            activeI:6
        },
    ]
}
