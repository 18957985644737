import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const languageKey = 'language';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "fr",
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          lang: {
            S:"State",
            Filter: "Filter ",
            world: "World",
            Overview: "Overview",
            TSO: "Total Sales Orders (NGN)",
            TP: "Top Products ",
            Jan: "Jan",
            Feb: "Feb",
            Mar: "Feb",
            Apr: "Apr",
            May: "May",
            June: "June",
            July: "July",
            Aug: "Aug",
            Sept: "Sept",
            Oct: "Oct",
            Nov: "Nov",
            Dec: "Dec ",
            TA: "Top Areas",
            Price: "Price",
            Availability: "Availability ",
            PS: "Product SKU",
            PN: "Product Name",
            SM: "See more",
            AP: "Add Product",
            PC: "Product Composition",
            NAFDAC: "NAFDAC Registration Number",
            PP: " Product Price",
            AS: "Availability Status",
            PD: " Product Description",
            SL: "Shelf Life (Years)",
            DASH: "Dashboard",
            PRO: "Product",
            MQ: "Manage Orders",
            LEAD: "Leads",
            CUSTOMER: "Customer",
            SCH: "Schedule",
            AC: "Admin Console",
            LG: "Log Out",
            TD: "Total Debt",
            TSC: "Total Sales Collections",
            TOC: "Total Order Count",
            TPO: "Total Paid Order",
            UCB: "User Chart Board",
            MA: "months ago",
            SO: "Sales Order",
            RO: "Recent Orders",
            VA: "View All",
            P: "Products",
            SFP: "Search For Products",
            R: "Report",
            OM: "Orders Management",
            OMS: "Search Orders, Field Staffs, Customer",
            S: "Search",
            C: "Customers",
            AC: "Add Customer",
            CAS: "Create New Schedule",
            AC: "Admin Console",
            PI: "Personal Information",
            PW: "Password",
            NFC: "Notification",
            FR: "Field Representatives",
            CP: "Company Profile",
            PPPP: "Privacy Policy",
            UYPPDH: "Update your photo and personal details here",
            UYCPDH:"Update your company photo and details here",
            PPP: "Profile Picture",
            D: "Discard",
            SC: "Save Changes",
            PNN: "Phone Number",
            FN: "First Name",
            LN: "Last Name",
            Role: "Role",
            HA: "Home Address",
            EA: "Email Address",
            CPP: "Change Photo.",
            jpeg: "(Only *.jpeg and *.png)",
            CC: "Current Password",
            NP: "New Password",
            CNP: "Confirm New Password",
            FieldRep: "Field Representatives",
            ANFRAUT: "Add new field representative and update them",
            ANP: "Add New Rep",
            CL:"Company's Logo",
            CN:"Company's Name",
            CA:"Company's Address",
            CITY:"City",
            PPS:"Privacy Policies",
            PP1:"How We Store, Process, and Secure Your Data",
            PP2:"Data Centers",
            PP3:"Data centers are a critical component of modern organizations and play a crucial role in supporting business..",
            PP4:"Network Security",
            PP5:"Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive..",
            PP6:"System Security",
            PP7:"Security Operations",
            PP8:"Access Control",
            PP9:"Vulnerabilty Assesment and Penetration Testing",
            PP10:"Application Level Security",
            PP11:"Data Protection, Continuity, and Retention",
            CCUS:"Contact us"
        
          },
          // here we will place our translations...
        },
      },
      fr: {
        translation: {
          lang: {
            S:"état",
            Filter: "Filtre",
            world: "World",
            Overview: "Tableau de bord",
            TSO: "Total des commandes (CFA)",
            TP: " Meilleurs produits",
            Jan: "janvier",
            Feb: "février",
            Mar: "mars",
            Apr: "avril",
            May: "mai",
            June: "juin",
            July: "juillet",
            Aug: "août",
            Sept: "septembre",
            Oct: "octobre",
            Nov: "novembre",
            Dec: "décembre  ",
            TA: "Principaux domaines",
            Price: "Prix",
            Availability: "disponibilité",
            PS: "UGS du produit",
            PN: "Nom du produit",
            SM: "Voir plus",
            AP: "Ajouter un produit",
            PC: "Composition du produit",
            NAFDAC: "Numéro d’enregistrement NAFDAC",
            PP: "Prix du produit",
            AS: " État de disponibilité ",
            PD: "Description du produit",
            SL: "Durée de Conservation (Années)",
            DASH: "Tableau de bord",
            PRO: "Produit",
            MQ: "Gérer les commandes",
            LEAD: "Prospects",
            CUSTOMER: "Client",
            SCH: "Planning",
            AC: `Console d'administration`,
            LG: "se déconnecter",
            TD: "Dette Totale",
            TSC: "Encaissements totaux des ventes",
            TOC: "Nombre total de commandes",
            TPO: "Commande totale payée",
            UCB: "Tableau de bord des utilisateurs",
            MA: "il y a plusieurs mois",
            SO: "Commande de vente",
            RO: "Commandes récentes",
            VA: "Voir tout",
            P: "Produits",
            SFP: "Rechercher des produits",
            R: "Rapport",
            OM: "Gestion des commandes",
            OMS: "Rechercher les commandes, les membres du personnel sur le terrain, les clients.",
            S: "Rechercher",
            C: "Clients",
            AC: "ajouter des clients",
            CAS: "créer un emploi du temps",
            AC: "Console d'administration",
            PI: "Informations personnelles",
            PW: "mot de passe",
            NFC: "Notification",
            FR: "Représentants en externe",
            CP: "profil de l'entreprise",
            PPPP: "politique de confidentialité",
            UYPPDH:
              "Mettez à jour votre photo et vos coordonnées personnelles ici",
              UYCPDH:"Mettez à jour la photo et les détails de votre entreprise ici.",
            PPP: "photo de profil",
            D: "rejeter",
            SC: "Sauvegarder les modifications",
            PNN: "numéro de téléphone",
            FN: "Prénom",
            LN: "Nom de famille",
            Role: "Poste",
            HA: "Adresse personnelle",
            EA: "Email Address",
            CPP: "Changer de photo.",
            jpeg: "(Uniquement *.jpeg et *.png)",
            CC: "Mot de passe actuel",
            NP: "Nouveau mot de passe",
            CNP: "Confirmer le nouveau mot de passe",
            FieldRep: "Représentants sur le terrain",
            ANFRAUT:
              "Ajouter un nouveau représentant sur le terrain et les mettre à jour",
            ANP: "Ajouter un nouveau représentant",
            CL:"Logo de l'entreprise",
            CN:"Nom de l'entreprise",
            CA:"Adresse de l'entreprise",
            CITY:"ville",
            PPS:"Politiques de confidentialité.",
            PP1:"Comment nous stockons, traitons et sécurisons vos données.",
            PP2:"Centres de données",
            PP3:"Les centres de données sont un élément essentiel des organisations modernes et jouent un rôle crucial dans le soutien aux activités commerciales..",
            PP4:"Sécurité du réseau.",
            PP5:"La sécurité du réseau est cruciale car les réseaux sont l'épine dorsale des organisations modernes et sont utilisés pour transmettre des informations sensibles...",
            PP6:"Sécurité du système.",
            PP7:"Opérations de sécurité.",
            PP8:"Contrôle d'accès",
            PP9:"Évaluation des vulnérabilités et tests de pénétration.",
            PP10:"Sécurité au niveau de l'application",
            PP11:"Protection des données, Continuité et Conservation",
            CCUS:"Contactez-nous"
          
          },
          // here we will place our translations...
        },
      },
    },
  });

  // Set the initial language from local storage
const storedLanguage = localStorage.getItem(languageKey);
if (storedLanguage) {
  i18n.changeLanguage(storedLanguage);
} else {
  // If no language is stored, set the default language
  const defaultLanguage = i18n.language || i18n.options.fallbackLng;
  localStorage.setItem(languageKey, defaultLanguage);
}


export default i18n;
